import React, { Component } from 'react';
import './Logo.css';

class Logo extends Component {

	render() {
		
		const classes = (this.props.classes || "" ) + (this.props.animate ? " logo animate" : " logo ");
		
		var color = "#233237";
		if(this.props.color) {
			color = this.props.color;
		}

		return (
			<svg version="1.1" id="Icon" xmlns="http://www.w3.org/2000/svg" className={classes} fill={color} viewBox="0 0 1024 1024">
				<g transform="matrix(1.44 0 0 1.43 512 386.29)" stroke={color} >
					<g id="logo-logo_1_" transform="matrix(0.24 0 0 -0.24 0 -0.01)">
						<path d="M560.6-803.1c217.8,0,394.4,176.7,394.4,394.4c0,193.3-139.2,354.2-322.8,387.9
							c-57.3,147-200.2,251-367.5,251c-48.3,0-94.5-8.7-137.2-24.5C48,271.2-53.7,310.4-164.5,310.4c-205.9,0-380.2-135.4-438.7-322.1
							C-801.2-34.1-955-202.1-955-406.1c0-219.2,177.7-397,397-397h209.2v-84.3c0-32.8-26.8-59.8-59.7-59.8h-102.9v-89.8h102.9
							c82.2,0,149.5,67.4,149.5,149.6v84.3v89.8V-522c0,77.8-60.4,142.2-136.7,148.9c-14.6,68-75,119.1-147.4,119.1
							c-83.3,0-150.9-67.6-150.9-150.9s67.5-150.9,150.9-150.9c62.9,0,116.8,38.5,139.4,93.3c30.6-2.5,54.9-28.2,54.9-59.5v-191.3H-558
							c-169.7,0-307.2,137.4-307.2,307.1c0,156,117.2,287.6,272.1,305.1l58.1,6.7l17.5,55.7c48.2,153.9,191.3,259.1,353,259.1
							c86,0,168.4-29.4,234.9-84.1l39.9-33l48.5,18.1c33.9,12.6,69.7,19,105.9,19c125.7,0,238.3-77.3,283.8-194l18.2-46.6l49.3-9.1
							C760.2-135.5,865.2-262,865.2-408.7c0-168.3-136.3-304.5-304.5-304.5h-166v186.6c0,24.6,15.1,45.9,36.5,55
							c26.4-44,74.3-73.5,129.3-73.5c83.3,0,150.9,67.6,150.9,150.8c0,83.3-67.5,150.9-150.9,150.9c-79.8,0-145-61.9-150.3-140.3
							c-60.9-19-105.4-76-105.4-142.8v-360.8c0-82.3,67.3-149.6,149.5-149.6h117.9v89.8H454.3c-32.8,0-59.7,26.9-59.7,59.8v84.3
							L560.6-803.1z M-543.1-466c-33.7,0-61.1,27.3-61.1,61.1s27.3,61.1,61.1,61.1c33.7,0,61.1-27.3,61.1-61.1S-509.4-466-543.1-466z
							M560.5-333.3c33.7,0,61.1-27.2,61.1-61.1c0-33.7-27.3-61.1-61.1-61.1c-33.7,0-61.1,27.3-61.1,61.1
							C499.4-360.5,526.7-333.3,560.5-333.3"/>
					</g>
					<g transform="matrix(0.24 0 0 -0.24 40.44 24.69)">
						<path d="M88.4,121.5C4,121.5-64.4,53.1-64.4-31.3c0-34.4,11.5-66.1,30.7-91.6c-34.7-27.9-57-70.5-57-118.1v-699.2h91
							V-241c0,31.8,24.9,58,56.2,60.2c10.3-2.2,21-3.3,31.9-3.3c84.4,0,152.8,68.4,152.8,152.8S172.8,121.5,88.4,121.5z M88.4-93.2
							c-7.2,0-14.1,1.3-20.6,3.6l0,0h-0.1c-24,8.5-41.2,31.4-41.2,58.3c0,34.1,27.7,61.8,61.8,61.8s61.8-27.6,61.8-61.8
							C150.2-65.4,122.6-93.2,88.4-93.2"/>
					</g>
					<g transform="matrix(0.24 0 0 -0.24 -28.41 20.59)">
						<path d="M99.6,0.8c0,84.2-68.3,152.5-152.5,152.5S-205.4,85-205.4,0.8c0-75.5,55-138,127.1-150.2v-0.3h1.5
							c6.6-1,13.3-1.6,20.2-1.7c26.2-6.7,45.8-30.4,45.8-58.6v-746.7H80V-210c0,36-12.7,69-33.8,95.1C78.8-87,99.6-45.6,99.6,0.8z
							M-114.6,0.8c0,34.1,27.6,61.8,61.7,61.8S8.8,35,8.8,0.8c0-32.7-25.5-59.5-57.7-61.5c-6.2,0.9-12.5,1.6-18.9,1.7
							C-94.6-52.3-114.6-28.1-114.6,0.8"/>
					</g>
				</g>
			</svg>
		  );
	}
}

export default Logo;