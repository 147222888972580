import React, { Component } from 'react';
import { Card } from 'turtle-ui';

class Tokens extends Component {

	render() {

		return (
			<Card classes="full-width text-more-muted center">API Keys</Card>
		  );
	}
}

export default Tokens;